import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import HeadingContent from '../components/HeadingContent';
import ProductCategoryListing from '../components/ProductCategoryListing';
import ProductListing from '../components/ProductListing';
import ModularBlocks from '../components/ModularBlocks';

const ProductCategoryPageTemplate = ({
  data: {
    datoCmsProductCategory: {
      seoMetaTags,
      title,
      bannerImage,
      bannerDarkOverlayOpacity,
      treeParent,
      introductionHeading,
      introductionContent,
      treeChildren,
      showProductFilters,
      modularBlocks,
    },
    shopifyCollection: { products },
  },
}) => (
  <Layout seo={seoMetaTags}>
    <main>
      <Banner
        heading={title}
        image={bannerImage}
        breadcrumbs={treeParent}
        darkOverlayOpacity={bannerDarkOverlayOpacity}
      />
      <HeadingContent
        heading={introductionHeading}
        content={introductionContent}
      />
      {treeChildren.length > 1 && !showProductFilters && (
        <ProductCategoryListing
          heading={`${title} Categories`}
          items={treeChildren}
        />
      )}
      {((treeChildren.length > 1 && products.length < 10) ||
        treeChildren.length < 2 ||
        showProductFilters) && (
        <ProductListing
          heading={`${title} Products`}
          items={products}
          showProductFilters={showProductFilters}
        />
      )}
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const ProductCategoryPageTemplateQuery = graphql`
  query ProductCategoryPageTemplateQuery(
    $id: String!
    $shopifyCollection: String!
  ) {
    datoCmsProductCategory(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage: featuredImage {
        ...SubPageBannerImageFragment
      }
      bannerDarkOverlayOpacity
      treeParent {
        title
        ...LinkFragment
        treeParent {
          title
          ...LinkFragment
          treeParent {
            title
            ...LinkFragment
          }
        }
      }
      introductionHeading
      introductionContent
      treeChildren {
        ...ProductCategoryCardFragment
      }
      showProductFilters
      modularBlocks {
        ...ContentModularBlockFragment
        ...HeadingContentModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...QuoteModularBlockFragment
        ...StatementTextModularBlockFragment
      }
    }
    shopifyCollection(handle: { eq: $shopifyCollection }) {
      products {
        ...ProductCardFragment
      }
    }
  }
`;

export default ProductCategoryPageTemplate;
