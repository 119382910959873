import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  visuallyHidden,
} from '../styles';
import { Container, Button } from './ui';
import ProductFilters from './ProductFilters';
import ProductCard from './ProductCard';

const StyledProductListing = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledInner = styled.div`
  ${({ showProductFilters }) => {
    if (showProductFilters) {
      return css`
        display: grid;
        gap: 20px;

        ${minBreakpointQuery.smedium`
          grid-template-columns: 1fr 2.8fr;
          align-items: start;
        `}

        ${minBreakpointQuery.medium`
          grid-template-columns: 1fr 3.2fr;
        `}
      `;
    }
  }}
`;

const StyledRefine = styled(Button)`
  ${maxBreakpointQuery.smedium`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.smedium`
    display: none;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tiny`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.small`
    row-gap: 25px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.large`
    row-gap: 30px;
  `}
`;

const ProductListing = ({
  heading,
  items: initialItems,
  showProductFilters,
}) => {
  const [items, setItems] = useState(initialItems);

  const [displayFilters, setDisplayFilters] = useState(false);

  return (
    items.length > 0 && (
      <StyledProductListing>
        <Container>
          <StyledHeading>{heading}</StyledHeading>
          <StyledInner showProductFilters={showProductFilters}>
            {showProductFilters && (
              <>
                <StyledRefine onClick={() => setDisplayFilters(true)}>
                  Refine Search
                </StyledRefine>
                <ProductFilters
                  initialProducts={initialItems}
                  setProducts={setItems}
                  display={displayFilters}
                  setDisplay={setDisplayFilters}
                />
              </>
            )}
            <StyledItems>
              {items.map(item => (
                <ProductCard key={item.id} {...item} />
              ))}
            </StyledItems>
          </StyledInner>
        </Container>
      </StyledProductListing>
    )
  );
};

export default ProductListing;
