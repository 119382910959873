import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  zIndexLayers,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Button } from './ui';
import { slugify } from '../utils';

const StyledProductFilters = styled.aside`
  padding: 30px;
  border: 1px solid ${standardColours.grey};

  ${maxBreakpointQuery.smedium`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background-color: ${standardColours.white};
    z-index: ${zIndexLayers.fourth};
    transform: translateX(-100%);
    transition: ${standardTransition('transform')};
  `}

  ${({ display }) => {
    if (display) {
      return css`
        ${maxBreakpointQuery.smedium`
          transform: translateX(0);
        `}
      `;
    }
  }}
`;

const StyledForm = styled.form``;

const StyledHeader = styled.header`
  ${maxBreakpointQuery.smedium`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledHeading = styled.p`
  color: ${standardColours.black};
  ${fontSize(18)};
`;

const StyledClose = styled.button`
  ${maxBreakpointQuery.smedium`
    color: ${standardColours.black};
    ${fontSize(13)};
    background: none;
    border: none;
  `}

  ${minBreakpointQuery.smedium`
    ${visuallyHidden()};
  `}

  &:after {
    ${maxBreakpointQuery.smedium`
      content: '✕';
      position: relative;
      bottom: 1px;
      margin-left: 12px;
      ${fontSize(22)};
      vertical-align: middle;
    `}
  }
`;

const StyledGroups = styled.div``;

const StyledCheckboxGroup = styled.fieldset`
  margin: 20px 0 0;
  padding: 0;
  border: none;
`;

const StyledCheckboxHeading = styled.legend`
  float: left;
  margin-bottom: 15px;
  padding: 0;
  width: 100%;
  color: ${brandColours.tertiary};
  ${fontSize(15)};
`;

const StyledOptions = styled.div``;

const StyledOption = styled.div`
  margin-top: 12px;
  clear: both;

  &:first-child {
    margin-top: 0;
  }
`;

// moved up for checked styling
const StyledText = styled.label`
  position: relative;
  display: block;
  padding-left: 30px;
  color: ${standardColours.black};
  ${fontSize(13)};
  line-height: 1.6;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid ${brandColours.tertiary};
    transition: ${standardTransition('background-color')};
  }

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 7px;
    height: 12px;
    width: 6px;
    border: 2px solid ${standardColours.transparent};
    border-top: none;
    border-left: none;
    border-radius: 1px;
    transform: rotate(45deg);
    transition: ${standardTransition('border-color')};
  }

  &:hover {
    &:after {
      border-color: ${brandColours.tertiary};
    }
  }
`;

const StyledCheckbox = styled.input`
  ${visuallyHidden()};

  &:checked + ${StyledText} {
    &:before {
      background-color: ${brandColours.tertiary};
    }

    &:after {
      border-color: ${standardColours.white};
    }
  }
`;

const StyledButton = styled(Button)`
  ${maxBreakpointQuery.smedium`
    margin-top: 40px;
    width: 100%;
  `}

  ${minBreakpointQuery.smedium`
    ${visuallyHidden()};
  `}
`;

const ProductFilters = ({
  initialProducts,
  setProducts,
  display,
  setDisplay,
}) => {
  const sizeFilters = [];
  const rangeFilters = [];
  const colourFilters = [];
  const ovenproofFilters = [];

  initialProducts.forEach(({ size, ranges, colour, ovenproof }) => {
    size && sizeFilters.push(size.value);
    ranges && rangeFilters.push(...ranges);
    colour && colourFilters.push(colour.value);
    ovenproof && ovenproofFilters.push(ovenproof.value);
  });

  const filters = [];

  if (sizeFilters.length > 0) {
    filters.push({
      name: 'Size',
      items: [...new Set(sizeFilters)].sort(),
    });
  }

  if (rangeFilters.length > 0) {
    filters.push({
      name: 'Range',
      items: [...new Set(rangeFilters)],
    });
  }

  if (colourFilters.length > 0) {
    filters.push({
      name: 'Colour',
      items: [...new Set(colourFilters)].sort(),
    });
  }

  if (ovenproofFilters.length > 0) {
    filters.push({
      name: 'Ovenproof',
      items: [...new Set(ovenproofFilters)].reverse(),
    });
  }

  const handleChange = e => {
    const form = e.currentTarget;
    const formData = new FormData(form);

    const selectedSizes = formData.getAll('size');
    const selectedRanges = formData.getAll('range');
    const selectedColours = formData.getAll('colour');
    const selectedOvenproof = formData.getAll('ovenproof');

    const filteredProducts = initialProducts.filter(
      ({ size, ranges, colour, ovenproof }) =>
        (selectedSizes.length > 0
          ? selectedSizes.includes(size && size.value)
          : true) &&
        (selectedRanges.length > 0
          ? selectedRanges.some(item => ranges.includes(item))
          : true) &&
        (selectedColours.length > 0
          ? selectedColours.includes(colour && colour.value)
          : true) &&
        (selectedOvenproof.length > 0
          ? selectedOvenproof.includes(ovenproof && ovenproof.value)
          : true)
    );

    setProducts(
      filteredProducts.length > 0 ? filteredProducts : initialProducts
    );
  };

  return (
    <StyledProductFilters display={display}>
      <StyledForm onChange={e => handleChange(e)}>
        <StyledHeader>
          <StyledHeading>Refine Search</StyledHeading>
          <StyledClose type="button" onClick={() => setDisplay(false)}>
            Close
          </StyledClose>
        </StyledHeader>
        <StyledGroups>
          {filters.map(
            ({ name, items }, id) =>
              items.length > 1 && (
                <StyledCheckboxGroup key={id}>
                  <StyledCheckboxHeading>{name}</StyledCheckboxHeading>
                  <StyledOptions>
                    {items.map((item, id) => {
                      const identifier = slugify(`${name}-${item}`);

                      return (
                        <StyledOption key={id}>
                          <StyledCheckbox
                            type="checkbox"
                            id={identifier}
                            name={slugify(name)}
                            value={item}
                          />
                          <StyledText for={identifier}>
                            {item === 'true'
                              ? 'Yes'
                              : item === 'false'
                              ? 'No'
                              : isNaN(item)
                              ? item
                              : parseInt(item)}
                            {name === 'Size' && 'cm'}
                          </StyledText>
                        </StyledOption>
                      );
                    })}
                  </StyledOptions>
                </StyledCheckboxGroup>
              )
          )}
        </StyledGroups>
        <StyledButton
          type="button"
          alt={true}
          onClick={() => setDisplay(false)}
        >
          Show Products
        </StyledButton>
      </StyledForm>
    </StyledProductFilters>
  );
};

export default ProductFilters;
